import React, { useRef, useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactToPrint from "react-to-print";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { getOrderApi, callPutApi } from "../../Services/apiservices";
import * as constants from "../../Constants/api";
import "./Order.css";
import EditIcon from "../../assets/editIcon.svg";
import CheckIcon from "../../assets/checkIcon.svg";

const Order = () => {
  const componentRef = useRef(null);

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id") || "";
  const token = queryParams.get("token") || "";
  const isConfirmed = queryParams.get("confirm") || null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [hideButtons, setHideButtons] = useState(false);
  const [pickup, setPickup] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [editingData, setEditingData] = useState(false);

  const inputRef = useRef();

  const onPressRejectOrder = async (storeId) => {
    setLoading(true);
    let url = constants.APIS.CANCEL_ORDER;
    let reqBody = undefined;
    try {
      const response = await callPutApi(url, reqBody, token);
      if (response.data) {
        alert("Order rejected successfully.");
        setHideButtons(true);
        setLoading(false);
      }
      await init();
    } catch (error) {
      alert("Something went wrong while rejecting order.");
      setLoading(false);
    }
  };

  const onPressConfirmOrder = async () => {
    setLoading(true);
    let url = constants.APIS.UPDATE_ORDER_STATUS;
    let reqBody = undefined;
    try {
      const response = await callPutApi(url, reqBody, token);
      if (response.data) {
        alert("Order confirmed successfully.");
        setHideButtons(true);
        setLoading(false);
      }
      await init();
    } catch (error) {
      alert("Something went wrong while confirming order.");
      setLoading(false);
    }
  };
  const onUpdatePickupTime = async () => {
    setLoading(true);
    let url = constants.APIS.UPDATE_ORDER_TIME;
    let reqBody = {
      pickup_time: pickup,
    };
    try {
      const response = await callPutApi(url, reqBody, token);
      if (response.data) {
        alert("Order Pickup time updated successfully.");

        setLoading(false);
      }
    } catch (error) {
      alert("Something went wrong while updating pickup time of order.");
      setLoading(false);
    }
  };

  const init = async () => {
    let url = constants.APIS.GET_ORDER;
    let reqBody = {
      order_id: orderId,
    };
    try {
      const response = await getOrderApi(url, reqBody, token);
      if (response.data) {
        setOrderInfo(response.data);
        setLoading(false);
        setPickup(
          moment(response.data?.pickup_time_estimated).format(
            "YYYY-MM-DDTHH:mm"
          )
        );
        // if (isConfirmed === "true") {
        //   onPressConfirmOrder();
        // } else if (isConfirmed === "false") {
        //   onPressRejectOrder(response.data.store_id);
        // }
      } else if (response.data.message) {
        alert(response.data.message);
      } else {
        alert("Unable to process your request!");
      }
    } catch (error) {
      alert("Error fetching order details..");
      setLoading(false);
      setError(true);
    }
  };
  useEffect(() => {
    setLoading(true);

    init();
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const getItemSizeForPrinterBill = () => {
    let size = 0;
    // orderInfo.items.forEach((item) => {
    //   if (item.item_available) {
    //     size = size + item.item_qty;
    //   }
    // });
    return orderInfo?.items?.length;
  };

  const capitalizeFirst = (word) => {
    if (!word || !word.length) {
      return "";
    }
    return word.charAt(0).toUpperCase() + word.toLowerCase().substring(1);
  };

  if (error) {
    return (
      <div className="error">
        <p className="errorText">Error fetching order details.</p>
      </div>
    );
  }

  if (orderInfo === null || loading) {
    return (
      <div className="loader">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      {!hideButtons &&
      !(orderInfo.order_status === "confirmed") &&
      !(orderInfo.order_status === "canceled") &&
      isConfirmed === null ? (
        <>
          <p className="confirmDsc">To receive payment confirm the order.</p>
          <div className="buttonContainer">
            <Button
              className="confirmOrderButton"
              onClick={onPressConfirmOrder}
              variant="success"
            >
              Confirm Order
            </Button>
            <Button
              className="rejectOrderButton"
              onClick={() => onPressRejectOrder(orderInfo.store_id)}
              variant="danger"
            >
              Deny Order
            </Button>
          </div>
        </>
      ) : (
        <p className="confirmDsc">Order Status : {orderInfo?.order_status}</p>
      )}
      <div className="printerConainer">
        <ReactToPrint
          trigger={() => <i className="fa-solid fa-print printIcon"></i>}
          content={reactToPrintContent}
          documentTitle="New Document"
          pageStyle="print"
        />
      </div>
      <div className="container" ref={componentRef}>
        <h1>TRAY BIRYANI</h1>
        <div className="headerContainer">
          <div>
            <div>
              <span className="title-text">Order Id :</span>
              <span> {orderInfo.order_id}</span>
            </div>
            <div>
              <span className="title-text">Customer :</span>
              <span> {orderInfo.customer_name}</span>
            </div>
            <div>
              <span className="title-text">Customer Phone :</span>
              <span> {orderInfo.customer_phonenumber}</span>
            </div>
            <div>
              <span className="title-text">Pickup Date & Time :</span>
              {!editingData ? (
                <span> {moment(pickup).format("ddd MMM,D h:mm A")}</span>
              ) : (
                <span>
                  <input
                    className="pickup-input"
                    type="datetime-local"
                    value={pickup}
                    ref={inputRef}
                    min={moment(orderInfo?.pickup_time_estimated).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    id="datetimeInput"
                    max={moment(orderInfo?.pickup_time_estimated)
                      .add(5, "days")
                      .endOf("day")
                      .format("YYYY-MM-DDTHH:mm")}
                    // max={moment()
                    //   .add(5, "days")
                    //   .endOf("day")
                    //   .format("YYYY-MM-DDTHH:mm")}
                    onClick={() => {
                      inputRef?.current?.showPicker?.();
                    }}
                    onChange={(e) => setPickup(e?.target?.value)}
                  />
                </span>
              )}
              {orderInfo.order_status !== "confirmed" &&
                orderInfo.order_status !== "canceled" && (
                  <button
                    className="EditBtn"
                    onClick={() => {
                      if (editingData) {
                        onUpdatePickupTime();
                        setEditingData(false);
                      } else {
                        setEditingData(true);
                      }
                    }}
                  >
                    <img src={editingData ? CheckIcon : EditIcon} />
                  </button>
                )}
            </div>
            {/* <div>
              <span className="title-text">Eating Type :</span>
              <span> {capitalizeFirst(orderInfo.eating_type)}</span>
            </div> */}
            <div>
              <span className="title-text">Total Items :</span>
              <span>{` ${getItemSizeForPrinterBill()} ${
                getItemSizeForPrinterBill() === 1 ? "Item" : "Items"
              }`}</span>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <Table>
            <tbody>
              {orderInfo.items.length > 0
                ? orderInfo.items.map((item) => (
                    <tr>
                      <td>
                        <span className="title-text">
                          {`${item.item_qty || 1}x ${item.name}`}
                          {item?.item_note && (
                            <span>
                              <br />
                              <small style={{ fontWeight: "normal" }}>
                                {item?.item_note}
                              </small>
                            </span>
                          )}
                        </span>
                        <Table borderless={true}>
                          <tbody>
                            {/* {item.options.length > 0 &&
                              item.options.map((option) => (
                                <tr>
                                  <td colSpan={2}>{option.option_name}</td>
                                  <td>{`$${option.option_price}`}</td>
                                </tr>
                              ))} */}
                          </tbody>
                        </Table>
                      </td>
                      <td>{`$${item?.totalPrice?.toFixed?.(2)}`}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="section-total">
        <div>
          <Table striped>
            <tbody>
              {/* <tr>
                <td colSpan={2}>{`Subtotal ${
                  orderInfo.total_discount !== 0 ? "(After Discount)" : ""
                } :`}</td>
                <td>{`$${orderInfo.subtotal.toFixed(2)}`}</td>
              </tr>
              <tr>
                <td colSpan={2}>{`Tax(${orderInfo.tax_percentage})% :`}</td>
                <td>{`$${orderInfo.tax_amount.toFixed(2)}`}</td>
              </tr> */}
              {/* <tr>
                <td colSpan={2}>Store Earned :</td>
                <td>{`$${orderInfo.store_earned.toFixed(2)}`}</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Order;
