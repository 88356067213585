import axios from '../Config/axios';

export const getOrderApi = (endPoint, data, token) => {
    return axios.put(endPoint, data, {
      headers: {
        Authorization: "Bearer "+ token,
        'Content-Type':'application/json',
    }
    })
}

export const callPutApi = (endPoint, data, token) => {
  return axios.put(endPoint, data, {
    headers: {
      Authorization: "Bearer "+ token,
      'Content-Type':'application/json',
  }
  })
}